import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { add } from 'date-fns';

const getYears = () => {
  const baseYear = 2022;
  const currentYear = new Date(Date.now()).getFullYear();
  const years = [{ text: '2022', value: 2022 }];

  for (let i = baseYear + 1; i <= currentYear; i++) {
    years.push({ text: i.toString(), value: i });
  }

  return years;
};

const getSelectedRange = (month, year) => {
  const start = new Date(year, month, 1);
  const end = add(start, { months: 1 });

  return { start, end };
};

type SelectedRange = {
  start: Date;
  end: Date;
};

export const useMonthYearDropdowns = () => {
  const { t } = useTranslation();

  const months = [
    { text: t('January'), value: 0 },
    { text: t('February'), value: 1 },
    { text: t('March'), value: 2 },
    { text: t('April'), value: 3 },
    { text: t('May'), value: 4 },
    { text: t('June'), value: 5 },
    { text: t('July'), value: 6 },
    { text: t('August'), value: 7 },
    { text: t('September'), value: 8 },
    { text: t('October'), value: 9 },
    { text: t('November'), value: 10 },
    { text: t('December'), value: 11 },
  ];

  const years = getYears();
  const defaultMonth = new Date(Date.now()).getMonth();
  const defaultYear = new Date(Date.now()).getFullYear();

  const [selectedMonth, setSelectedMonth] = useState<number>(defaultMonth);
  const [selectedYear, setSelectedYear] = useState<number>(defaultYear);
  const [selectedRange, setSelectedRange] = useState<SelectedRange>(getSelectedRange(defaultMonth, defaultYear));

  useEffect(() => {
    setSelectedRange(getSelectedRange(selectedMonth, selectedYear));
  }, [selectedMonth, selectedYear]);

  return {
    months,
    years,
    selectedMonth,
    selectedYear,
    setSelectedMonth,
    setSelectedYear,
    selectedRange,
  };
};
