import React, { useEffect } from 'react';
import { navigate, PageProps } from 'gatsby';
import { Helmet } from 'react-helmet';

import { useAuthContext } from 'src/components/AuthProvider';
import useCheckTermsConditionsStatus from 'src/api/useCheckTermsConditions';
import { isAgent } from 'src/api/client';

import LoadingContainer from 'src/components/LoadingContainer';
import CommissionsDataTableContainer from 'src/components/CommissionsDataTableContainer/CommissionsDataTableContainer';
import StateFarmPageTemplate from 'src/components/templates/StateFarmPageTemplate/StateFarmPageTemplate';
import PageLayoutWithSidebar from 'src/components/templates/layouts/PageLayoutWithSidebar/PageLayoutWithSidebar';
import { HelpBox } from 'src/components/molecule/HelpBox';
import { Translate } from 'src/components/Translate';
import { useTracking, useTrackingContext } from 'src/components/TrackingContext';

const metadata = {
  title: 'Agent Compensation Report',
};

export default function CommissionsIndexPage({ location }: PageProps) {
  const { loading: authLoading } = useAuthContext();
  const { status: termsStatus, loading: termsLoading } = useCheckTermsConditionsStatus();
  const { trackInteraction } = useTrackingContext();

  useTracking('sf_commissions_view');

  const isPageLoading = authLoading || termsLoading;

  const handleWorkdayClick = () => {
    trackInteraction('link', 'here');
  };

  const i18n = {
    pageHeading: 'commissions.pageHeading',
    pageText: 'commissions.pageText',
    helpHeading: 'commissions.helpHeading',
    helpText: 'commissions.helpText',
    helpLinkHere: 'commissions.helpText.here',
  };

  useEffect(() => {
    if (!isAgent) {
      navigate('/');
    }
  }, []);

  if (isPageLoading) {
    return (
      <StateFarmPageTemplate showFooterDisclaimer={false}>
        <LoadingContainer height={500} />
      </StateFarmPageTemplate>
    );
  }

  if (isAgent && termsStatus !== 'VALID') {
    navigate(`terms/${location.search}`);
  }

  return (
    <>
      <Helmet title={metadata.title} />
      <StateFarmPageTemplate className="agent-commissions">
        <PageLayoutWithSidebar
          content={
            <div className="agent-commissions__main">
              <Translate as="h2" resourceKey={i18n.pageHeading} />
              <p className="text-lead_2">
                <Translate resourceKey={i18n.pageText} />
              </p>
              <CommissionsDataTableContainer />
            </div>
          }
          sidebar={
            <HelpBox
              header={i18n.helpHeading}
              content={
                <span>
                  <Translate resourceKey={i18n.helpText} />{' '}
                  <form method="POST" action={process.env.HDC_WORKDAY_URL} className="agent-commissions__form">
                    <button
                      data-cy="agent-commissions-workday-link"
                      className="agent-commissions__link"
                      type="submit"
                      onClick={handleWorkdayClick}
                    >
                      <Translate resourceKey={i18n.helpLinkHere} />
                    </button>
                    .
                  </form>
                </span>
              }
            />
          }
        />
      </StateFarmPageTemplate>
    </>
  );
}
