export type CommissionRowData = {
  customerName: string;
  date: string;
  type: string;
  amount: string;
  hdcMemberNum: string;
  compType: string;
  compAmount: string;
};

export interface DataTableRow {
  month: string;
  year: string | number;
  data: CommissionRowData[];
}

export enum CompensationKind {
  Commission = 'COMMISSION',
  Referral = 'REFERRAL',
}

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const hdcLevels = {
  GEAR_1: '1st Gear',
  GEAR_2: '2nd Gear',
  GEAR_3: '3rd Gear',
  FREE_TRIAL: 'Free Trial',
};

export const columnData = [
  {
    Header: 'Customer Name',
    accessor: 'customerName',
    sortType: 'string',
  },
  {
    Header: 'Date',
    accessor: 'date',
    sortType: 'string',
    sortDescFirst: true,
  },
  {
    Header: 'Type',
    accessor: 'type',
    disableSortBy: true,
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    disableSortBy: true,
  },
  {
    Header: 'HDC Member #',
    accessor: 'hdcMemberNum',
    disableSortBy: true,
  },
  {
    Header: 'Comp Type',
    accessor: 'compType',
    disableSortBy: true,
  },
  {
    Header: 'Comp Amount',
    accessor: 'compAmount',
    disableSortBy: true,
  },
];

export const testQueryResults = {
  data: {
    commissions: {
      agent: {
        __typename: 'HdcAgent',
        id: 'FGM9N1YS000',
        name: 'Harvey Adams',
        company: {
          id: 'STFRM',
        },
        invoicing: {
          agentId: '67c6e5f3-cb64-43a9-a399-24ac85be3465',
        },
        policy: {
          agentId: '67c6e5f3-cb64-43a9-a399-24ac85be3465',
        },
        transactions: {
          pageInfo: {
            hasNextPage: true,
            hasPreviousPage: false,
            endCursor:
              'U2FsdGVkX1/Uvdrl6PztdHQum+C9Uj7INmsysePnoPMGeTwtEgYdFiHWZ5G2svihcwVU1JFYFKCPqLhw8cHvbFrb+lc+mdSAAfIqfMsut/Db3RuGRgxdyw/K8tni0eb6/cPF02qu9vtFOChhe1Q3+djbHvV0EwrafBzps4Pjhchbb3i6+v08yAAlDDzV0pez',
          },
          edges: [
            {
              node: {
                id: '3a7cb125-cc1e-46dc-9d07-21d715f77d3d',
                customerName: 'Thom Shelbyton',
                kind: 'COMMISSION',
                date: '2022-03-20T09:09:14.101Z',
                lastUpdatedAt: '2021-12-21T19:49:11.499Z',
                type: 'Sale',
                value: {
                  amount: 35,
                  currency: 'USD',
                },
                company: {
                  id: 'STFRM',
                  reference: 'CA-v1-company',
                  calculatedCommission: {
                    amount: 2,
                    currency: 'USD',
                  },
                },
                agent: {
                  id: 'FGM9N1YS000',
                  reference: 'CA-v1-agent',
                  calculatedCommission: {
                    amount: 6,
                    currency: 'USD',
                  },
                  invoice: {
                    id: '2021-05-20 Jayne Dietrich HDC10999999 HDC',
                  },
                },
                order: {
                  id: 'b7882bbfb19d42e88be61e69d7d6942b',
                  userId: '7a3a93e0-ff4b-4507-8560-89333a409940',
                  hdcNumber: 'HDC10999999',
                  product: {
                    membershipKind: 'GEAR_1',
                  },
                },
              },
            },
            {
              node: {
                id: '7a54a695-4739-4502-812d-fceb09f98422',
                customerName: 'Lori Shelbyson',
                kind: 'COMMISSION',
                date: '2022-04-22T12:10:14.101Z',
                lastUpdatedAt: '2021-12-21T18:29:18.655Z',
                type: 'Sale',
                value: {
                  amount: 15000,
                  currency: 'USD',
                },
                company: {
                  id: 'STFRM',
                  reference: 'CA-v1-company',
                  calculatedCommission: {
                    amount: 600,
                    currency: 'USD',
                  },
                },
                agent: {
                  id: 'FGM9N1YS000',
                  reference: 'CA-v1-agent',
                  calculatedCommission: {
                    amount: 2400,
                    currency: 'USD',
                  },
                  invoice: {
                    id: '',
                  },
                },
                order: {
                  id: '01b66b2653414e95bf5f8ed64e1146c3',
                  userId: '90f2673f-4d6a-414d-a20c-b552cec334ec',
                  hdcNumber: '1234568',
                  product: {
                    membershipKind: 'GEAR_2',
                  },
                },
              },
            },
          ],
        },
      },
    },
  },
};
