import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useAuthContext } from 'src/components/AuthProvider';
import useAgentCommissions from 'src/api/useAgentComissions';
import { stfrm } from 'src/constants/agent';

import LoadingContainer from 'src/components/LoadingContainer';
import { DataTable } from 'src/components/molecule/DataTable';
import { hdcLevels, columnData, CommissionRowData, CompensationKind } from './CommissionsDataTable.types';
import { useMonthYearDropdowns } from 'src/components/CommissionsDataTableContainer/useMonthYearDropdowns';
import styles from './CommissionsDataTable.module.scss';

const DEFAULT_PAGE_SIZE = 20;

const CommissionsDataTableContainer = () => {
  const { brokers } = useAuthContext();
  const [tableData, setTableData] = useState([]);
  const {
    months,
    years,
    selectedMonth,
    selectedYear,
    setSelectedMonth,
    setSelectedYear,
    selectedRange,
  } = useMonthYearDropdowns();
  const {
    commissions,
    loading: commissionsLoading,
    pageLeft,
    pageRight,
    showPageLeft,
    showPageRight,
    pageNumber,
  } = useAgentCommissions({
    id: brokers.agentId,
    companyId: stfrm,
    first: DEFAULT_PAGE_SIZE,
    startTime: selectedRange.start.toISOString(),
    endTime: selectedRange.end.toISOString(),
  });

  const handleMonthChanged = (e) => {
    setSelectedMonth(parseInt(e.currentTarget.value));
  };

  const handleYearChanged = (e) => {
    setSelectedYear(parseInt(e.currentTarget.value));
  };

  // Parse the commissions data when we recieve it.
  useEffect(() => {
    // For local testing
    // const data = commissions && commissions.length < 1 ? testQueryResults.data.commissions.agent.transactions.edges : commissions;
    if (!commissions) {
      return;
    }

    const data = [...commissions];

    const adaptedData: CommissionRowData[] = [];

    //Sort by date
    data.sort((a, b) => {
      if (a.node.date > b.node.date) return b;
      return a;
    });

    data.forEach((elm) => {
      const elmDate = new Date(elm.node.date);

      const adapted = {
        customerName: elm?.node?.customerName,
        date: format(elmDate, 'yyyy-MM-dd'),
        type: elm?.node?.actionType,
        amount:
          elm?.node?.kind === CompensationKind.Commission && elm?.node?.value?.amount
            ? (elm.node.value.amount / 100).toLocaleString('en-US', {
                style: 'currency',
                currency: elm.node.value.currency,
              })
            : '$0',
        hdcMemberNum: elm?.node?.order?.hdcNumber,
        compType: elm?.node?.kind.charAt(0) + elm?.node?.kind?.substr(1).toLowerCase(),
        compAmount: (elm?.node?.agent?.calculatedCommission?.amount / 100).toLocaleString('en-US', {
          style: 'currency',
          currency: elm?.node?.agent?.calculatedCommission?.currency,
        }),
      };

      adaptedData.push(adapted);
    });

    setTableData(adaptedData);
  }, [commissions]);

  if (commissionsLoading) {
    return (
      <div className="data-table__container">
        <LoadingContainer height={350} />
      </div>
    );
  }

  return (
    <div className="data-table__container" data-cy="commissions-table">
      <div className={styles.dateContainer}>
        <div className={`${styles.dateInput} form-element filter-selector`}>
          <div className="form-element__control">
            <label htmlFor="month-select" className={styles.dateLabel}>
              Month
            </label>
            <div className="input-select__wrapper">
              <select
                id="month-select"
                data-cy="commissions-month-select"
                className="input-select"
                aria-label="Month selected for HDC Commissions"
                value={selectedMonth}
                onChange={handleMonthChanged}
              >
                {months.map((m) => (
                  <option value={m.value} key={m.value}>
                    {m.text}
                  </option>
                ))}
                Test
              </select>
            </div>
          </div>
        </div>

        <div className={`${styles.dateInput} form-element filter-selector`}>
          <div className="form-element__control">
            <label htmlFor="year-select" className={styles.dateLabel}>
              Year
            </label>
            <div className="input-select__wrapper">
              <select
                id="year-select"
                data-cy="commissions-year-select"
                className="input-select"
                aria-label="Year selected for HDC Commissions"
                value={selectedYear}
                onChange={handleYearChanged}
              >
                {years.map((y) => {
                  return (
                    <option value={y.value} key={y.value}>
                      {y.text}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
      </div>
      <DataTable
        columns={columnData}
        data={tableData}
        pageLeft={pageLeft}
        pageRight={pageRight}
        showPageLeft={showPageLeft}
        showPageRight={showPageRight}
        pageNumber={pageNumber}
        pageSize={DEFAULT_PAGE_SIZE}
      />
    </div>
  );
};

export default CommissionsDataTableContainer;
