import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { QueryHookOptions } from '@apollo/client/react/types/types';

import { isAgent, ServiceName } from 'src/api/client';

import { useAuthContext } from 'src/components/AuthProvider';
import getCommissions from './graphql/brokers/queries/AgentCommissionsQuery.graphql';
import { useCursorPagination } from 'src/hooks/useCursorPagination';

export type AgentCommissionsVars = {
  id: string;
  companyId: string | string[];
  first: number;
  startTime: string; // YYYY-mm-dd
  endTime: string; // YYYY-mm-dd
};

const usePaginatedAgentCommissions = (vars?: AgentCommissionsVars) => {
  if (!isAgent || !vars) {
    return {};
  }

  const {
    showPageLeft,
    showPageRight,
    pageNumber,
    currentCursor,
    pageLeft,
    pageRight,
    isDataLoaded,
    dataLoaded,
    resetPagination,
  } = useCursorPagination();
  const { isAuthenticated } = useAuthContext();

  const options: QueryHookOptions = {
    context: { serviceName: ServiceName.Terms },
    skip: !isAuthenticated,
    fetchPolicy: 'no-cache',
    variables: {
      ...vars,
      after: currentCursor,
    },
  };

  /* We have to reset the pagination state when we change date ranges */
  useEffect(() => {
    resetPagination();
  }, [vars.startTime, vars.endTime]);

  const { data, loading } = useQuery(getCommissions, options);

  useEffect(() => {
    if (!isDataLoaded && !loading) {
      dataLoaded(data?.commissions?.agent?.transactions?.pageInfo?.endCursor);
    }
  }, [loading, data?.commissions?.agent?.transactions?.pageInfo?.endCursor]);

  return {
    commissions: data?.commissions?.agent?.transactions?.edges,
    loading,
    showPageLeft,
    showPageRight,
    pageNumber,
    pageLeft,
    pageRight,
  };
};

export default usePaginatedAgentCommissions;
