import { useEffect, useReducer, useState } from 'react';

export enum PaginationAction {
  PAGE_LEFT = 'PAGE_LEFT',
  PAGE_RIGHT = 'PAGE_RIGHT',
  DATA_LOADED = 'DATA_LOADED',
  RESET_PAGINATION = 'RESET_PAGINATION',
}

export type PaginationState = {
  prevCursors: string[];
  nextCursor: string | null;
  isDataLoaded: boolean;
};

type PaginationReducerAction = {
  type: PaginationAction;
  payload?: string | null;
};

const paginationReducer = (state: PaginationState, action: PaginationReducerAction) => {
  switch (action.type) {
    case PaginationAction.PAGE_LEFT: {
      if (state.prevCursors.length === 0) {
        return state;
      }

      const prevCursors = [...state.prevCursors];
      const nextCursor = prevCursors.pop();

      return {
        prevCursors,
        nextCursor,
        isDataLoaded: false,
      };
    }
    case PaginationAction.PAGE_RIGHT: {
      console.log('PAGE_RIGHT');
      if (state.nextCursor === null) {
        return state;
      }

      return {
        prevCursors: [...state.prevCursors, state.nextCursor],
        nextCursor: null,
        isDataLoaded: false,
      };
    }
    case PaginationAction.DATA_LOADED: {
      console.log('DATA_LOADED');
      if (action.payload === undefined) {
        return state;
      }

      return {
        ...state,
        nextCursor: action.payload,
        isDataLoaded: true,
      };
    }
    case PaginationAction.RESET_PAGINATION: {
      return {
        prevCursors: [],
        nextCursor: null,
        isDataLoaded: false,
      };
    }
  }
};

export const useCursorPagination = () => {
  const [pageState, dispatch] = useReducer(paginationReducer, {
    prevCursors: [],
    nextCursor: null,
    isDataLoaded: false,
  });

  const [showPageLeft, setShowPageLeft] = useState(false);
  const [showPageRight, setShowPageRight] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [currentCursor, setCurrentCursor] = useState(null);

  useEffect(() => {
    if (pageState.prevCursors.length > 0) {
      setShowPageLeft(true);
    } else {
      setShowPageLeft(false);
    }

    if (pageState.nextCursor !== null) {
      setShowPageRight(true);
    } else {
      setShowPageRight(false);
    }

    setPageNumber(pageState.prevCursors.length + 1);
    setCurrentCursor(pageState.prevCursors.length > 0 ? pageState.prevCursors[pageState.prevCursors.length - 1] : null);
  }, [pageState.prevCursors, pageState.nextCursor]);

  const pageLeft = () => {
    dispatch({ type: PaginationAction.PAGE_LEFT });
  };

  const pageRight = () => {
    dispatch({ type: PaginationAction.PAGE_RIGHT });
  };

  const dataLoaded = (nextCursor?: string | null) => {
    dispatch({ type: PaginationAction.DATA_LOADED, payload: nextCursor });
  };

  const resetPagination = () => {
    dispatch({ type: PaginationAction.RESET_PAGINATION });
  };

  return {
    prevCursors: pageState.prevCursors,
    nextCursor: pageState.nextCursor,
    isDataLoaded: pageState.isDataLoaded,
    showPageLeft,
    showPageRight,
    pageNumber,
    currentCursor,
    pageLeft,
    pageRight,
    dataLoaded,
    resetPagination,
  };
};
