import React from "react";
import './PageLayoutWithSidebar.module.scss';

type PageLayoutWithSidebarProps = {
    content: React.ReactNode;
    sidebar: React.ReactNode;
};

const PageLayoutWithSidebar = ({ content, sidebar }: PageLayoutWithSidebarProps) => {
    return (
        <div className="grid grid-wrap gutters_between page-layout-with-sidebars">
            <div className="col xs-size_1-of-1 ws-size_9-of-12">
                {content}
            </div>
            <div className="col xs-size_1-of-1 ws-size_3-of-12 sidebar">
                {sidebar}
            </div>
        </div>
    );
}

export default PageLayoutWithSidebar;
