import React from 'react';
import { Translate } from 'src/components/Translate';
import { Bulb24, Magazine32 } from '@hagerty/icons';

type HelpBoxProps = {
  header: string;
  content: string | JSX.Element;
  icon?: 'bulb' | 'magazine';
};

export const HelpBox = ({ header, content, icon = 'bulb' }: HelpBoxProps) => {
  return (
    <div className="help-box" data-cy="help-box">
      <div className="help-box__icon">
        {icon === 'bulb' && <Bulb24 className={'icon icon_large'} />}
        {icon === 'magazine' && <Magazine32 className={'icon icon_large'} />}
      </div>
      <div className="help-box__header">
        <Translate resourceKey={header} />
      </div>
      <div className="help-box__content">{content}</div>
    </div>
  );
};
