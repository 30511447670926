import React from 'react';
import { useTable, usePagination, useSortBy } from 'react-table';
import { LeftFlush16, RightFlush16, SmallTriangleDown16 } from '@hagerty/icons';
import { DataTableProps } from './DataTable.types';
import { Translate } from 'src/components/Translate';

export const DataTable = <RowDataType extends object>({
  columns,
  data,
  pageLeft,
  pageRight,
  showPageLeft,
  showPageRight,
  pageNumber,
  ...props
}: DataTableProps<RowDataType>): JSX.Element => {
  const DEFAULT_PAGE_SIZE = 2;
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: props.pageSize || DEFAULT_PAGE_SIZE,
        sortBy: [{ id: 'date', desc: true }],
      },
    },
    useSortBy,
    usePagination
  );

  const i18n = {
    noSales: 'commissions.table.noSales',
  };

  const getTableBody = (rowData) => {
    return (
      <tbody {...getTableBodyProps()}>
        {page.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} data-cy="data-table-row">
              {row.cells.map((cell) => {
                return (
                  <td data-cy="data-table-cell" {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    );
  };

  const getEmptyResults = () => {
    return (
      <div className="data-table__empty" data-cy="data-table-empty-results">
        <p>
          <Translate resourceKey={i18n.noSales} />
        </p>
      </div>
    );
  };

  const getShowCountArray = () => {
    return [5, 10, 20, 30, 40, 50];
  };

  const getPagination = () => {
    if (!showPageRight && !showPageLeft) {
      return null;
    }

    return (
      <div className="pagination" data-cy="data-table-pagination">
        <div className="pagination__controls">
          <button className="button" disabled={!showPageLeft} onClick={pageLeft} data-cy="data-table-page-left">
            <LeftFlush16 className="icon icon_large" />
          </button>
          <span className="page-count" data-cy="data-table-page-number">
            Page {pageNumber}
          </span>
          <button className="button" disabled={!showPageRight} onClick={pageRight} data-cy="data-table-page-right">
            <RightFlush16 className="icon icon_large" />
          </button>
        </div>
      </div>
    );
  };

  const getSortableColumnIconClass = (colData) => {
    let clsName = '';
    if (colData.isSorted) {
      clsName = colData.isSortedDesc ? 'icon_desc' : 'icon_asc';
    }
    return clsName;
  };

  const getSortableColumnSortValue = (colData) => {
    if (colData.disableSortBy) return undefined;

    if (colData.isSorted) {
      return colData.isSortedDesc ? 'descending' : 'ascending';
    }

    return 'none';
  };

  return (
    <div className="data-table">
      <div className="data-table__wrapper">
        <table {...getTableProps()} data-cy="data-table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr role="row" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    role="columnheader"
                    aria-sort={getSortableColumnSortValue(column)}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
                      {column.render('Header')}
                      {column.canSort ? (
                        <button className="sortable-icon__wrapper" type="button">
                          <SmallTriangleDown16 className={'icon sortable-icon ' + getSortableColumnIconClass(column)} />
                        </button>
                      ) : (
                        ''
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {getTableBody(page)}
        </table>
        {page.length === 0 ? getEmptyResults() : ''}
      </div>
      {page.length === 0 ? '' : getPagination()}
    </div>
  );
};
