import { useQuery } from '@apollo/client';
import { useAuthContext } from 'src/components/AuthProvider';
import { QueryHookOptions } from '@apollo/client/react/types/types';

import { isAgent, ServiceName } from 'src/api/client';

import getSignatureStatus from './graphql/brokers/queries/CheckTermsConditionsStatusQuery.graphql';

const useCheckTermsConditionsStatus = () => {
  const { isAuthenticated, loading: authLoading } = useAuthContext();
  const options: QueryHookOptions = {
    context: { serviceName: ServiceName.Terms },
    skip: !isAuthenticated,
    fetchPolicy: 'network-only',
  };
  if (!isAgent) {
    return {};
  }
  const { data, loading, refetch } = useQuery(getSignatureStatus, options);
  return { status: data?.termsAndConditions.currentSignature.status, loading: loading || authLoading, refetch };
};

export default useCheckTermsConditionsStatus;
